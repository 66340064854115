<template>
  <div>
    <div class="container form-signin3">
      <div class="row">
        <div class="col-md-12" style="padding-left: 50px; padding-right: 50px">
          <PageTitle title="POSITION BOOK" />
          <form
            class="form-inline transparent-form border-page m-t-2 order-form-content">
            <div
              class="col-lg-12 col-sm-12 row"
              style="padding-right: 0px !important">
              <div class="col-lg-1 col-sm-2 col-xl-auto handle-radi">
                <div class="custom-control custom-radio mb-1">
                  <Field
                    type="radio"
                    value="ALL"
                    id="gross_partnership"
                    name="net_gross_partner"
                    v-model="net_qty"
                    v-on:change="getList()"
                    class="custom-control-input ng-pristine ng-untouched ng-valid"
                    checked="checked"
                    style="height: unset !important" />
                  <label
                    style="
                      font-size: 13px;
                      padding-top: 5px;
                      justify-content: flex-start;
                    "
                    for="gross_partnership"
                    class="custom-control-label mt-1 lable-text"
                    >All</label
                  >
                </div>
                <div class="custom-control custom-radio">
                  <Field
                    v-model="net_qty"
                    type="radio"
                    value="OUTSTANDING"
                    id="gross_partnership2"
                    v-on:change="getList()"
                    name="net_gross_partner"
                    class="custom-control-input ng-pristine ng-untouched ng-valid"
                    checked="checked"
                    style="height: unset !important" />
                  <label
                    style="
                      font-size: 13px;
                      padding-top: 5px;
                      justify-content: flex-start;
                    "
                    for="gross_partnership2"
                    class="custom-control-label mt-1 lable-text"
                    >Outstanding</label
                  >
                </div>
                <span class="text-error"></span>
              </div>
              <div class="col-lg-1 col-sm-2 col-xl-auto handle-rad">
                <div
                  class="custom-control custom-radio mb-1"
                  v-if="role != 'USER'">
                  <Field
                    v-model="data_type"
                    type="radio"
                    v-on:change="getList()"
                    value="CLIENT_WISE"
                    id="CLIENT_WISE"
                    name="CLIENT_WISE"
                    class="custom-control-input ng-pristine ng-untouched ng-valid"
                    checked="checked"
                    style="height: unset !important" />
                  <label
                    style="
                      font-size: 13px;
                      padding-top: 5px;
                      justify-content: flex-start !important;
                    "
                    for="CLIENT_WISE"
                    class="custom-control-label mt-1 lable-text"
                    >Client Wise</label
                  >
                </div>
                <div class="custom-control custom-radio mb-1">
                  <Field
                    v-model="data_type"
                    type="radio"
                    v-on:change="getScriptWise()"
                    value="SCRIPT_WISE"
                    id="SCRIPT_WISE"
                    name="SCRIPT_WISE"
                    class="custom-control-input ng-pristine ng-untouched ng-valid"
                    checked="checked"
                    style="height: unset !important" />
                  <label
                    style="
                      font-size: 13px;
                      padding-top: 5px;
                      justify-content: flex-start !important;
                    "
                    for="SCRIPT_WISE"
                    class="custom-control-label mt-1 lable-text"
                    >Script Wise</label
                  >
                </div>
                <div class="custom-control custom-radio mb-1">
                  <Field
                    v-model="data_type"
                    type="radio"
                    v-on:change="getScriptWise()"
                    value="SELF_SCRIPT_WISE"
                    id="SELF_SCRIPT_WISE"
                    name="SELF_SCRIPT_WISE"
                    class="custom-control-input ng-pristine ng-untouched ng-valid"
                    checked="checked"
                    style="height: unset !important" />
                  <label
                    style="
                      font-size: 13px;
                      padding-top: 5px;
                      justify-content: flex-start !important;
                    "
                    for="SELF_SCRIPT_WISE"
                    class="custom-control-label mt-1 lable-text"
                    >Self Script Wise</label
                  >
                </div>
                <span class="text-error"></span>
              </div>
              <div class="col-lg-7 col-sm-7 row">
                <div class="col-lg-3 col-sm-10 mt-1">
                  <Multiselect
                    mode="single"
                    trackBy="name"
                    label="name"
                    valueProp="value"
                    class="register-select"
                    placeholder="Segment"
                    v-model="exchange"
                    :options="exchanges"
                    searchable="true"
                    @select="getList()" />
                </div>

                <div
                  class="col-lg-3 col-sm-10 mt-1"
                  v-if="role != 'USER' && role != 'BROKER' && role != 'MASTER'">
                  <Multiselect
                    mode="single"
                    trackBy="new_name"
                    label="new_name"
                    valueProp="id"
                    class="register-select"
                    placeholder="Master"
                    v-model="master_id"
                    :options="master_list"
                    @select="getList()"
                    searchable="true" />
                </div>
                <div
                  class="col-lg-3 col-sm-10 mt-1"
                  v-if="role != 'USER' && role != 'BROKER'">
                  <Multiselect
                    mode="single"
                    trackBy="name"
                    label="name"
                    valueProp="id"
                    class="register-select"
                    placeholder="Broker"
                    v-model="broker_id"
                    :options="brokerFilterList"
                    @select="getList()"
                    searchable="true" />
                </div>
                <div class="col-lg-3 col-sm-10 mt-1" v-if="role != 'USER'">
                  <Multiselect
                    mode="single"
                    trackBy="new_name"
                    label="new_name"
                    valueProp="id"
                    class="register-select"
                    placeholder="Client"
                    v-model="user_id"
                    :options="client_list"
                    searchable="true"
                    @select="getList()" />
                </div>
                <div class="col-lg-3 col-sm-10 mt-1">
                  <datepicker
                    v-model="expireDate"
                    class="register-select"
                    placeholder="Expiry Date"
                    @select="getList()" />
                </div>
                <div
                  class="col-lg-3"
                  style="text-align: left"
                  v-if="role == 'SUPER_MASTER'">
                  <!-- <label class="col-form-label" style="display: flex">
                    Manager
                    <span class="text-danger">*</span>
                  </label> -->
                  <Multiselect
                    mode="single"
                    trackBy="name"
                    label="name"
                    valueProp="id"
                    class="register-select"
                    placeholder="Manager"
                    v-model="manager_id"
                    :options="manager_list"
                    @select="getList()"
                    searchable="true" />
                </div>
              </div>
              <div class="order-div mt-1">
                <button
                  id="btn-search"
                  type="button"
                  v-on:click="getList()"
                  class="btn btn-primary mb-2">
                  Search
                </button>
                &nbsp;
                <button
                  type="button"
                  v-on:click="reset"
                  class="btn btn-secondary mb-2">
                  Reset
                </button>
                <downloadexcel
                  v-if="this.$store.getters.getAccess?.is_PositionExport"
                  class="btn btn-warning mb-2 ml-2"
                  :fetch="fetchData"
                  name="Position-Report.xls"
                  :fields="json_fields"
                  :before-generate="startDownload"
                  :before-finish="finishDownload">
                  <span class="align-items-center d-flex">Export</span>
                </downloadexcel>
              </div>
            </div>
          </form>
        </div>
      </div>

      <div
        class="order-table-content row"
        style="padding-left: 50px; padding-right: 50px; margin-top: 5px">
        <div class="col-lg-12 col-md-12 col-sm-12 bg-light border-page">
          <div
            class="row col-lg-12 col-md-12 col-sm-12 order-table-searchbar"
            style="justify-content: flex-end; display: flex">
            <div
              v-if="this.$store.getters.getAccess?.is_rollover"
              class="col-lg-2 col-md-2 col-sm-2 mb-1"
              style="justify-content: flex-end; display: flex">
              <button
                id="btn-dd"
                type="button"
                style="background-color: orange !important"
                v-on:click="rollOverPosition()"
                class="btn btn-primary mb-2">
                Rollover
              </button>
            </div>
            <div
              v-if="this.$store.getters.getAccess?.is_closePosition"
              class="col-lg-2 col-md-2 col-sm-2 mb-1"
              style="justify-content: flex-end; display: flex">
              <button
                id="btn-dd"
                type="button"
                v-on:click="closePosition()"
                class="btn btn-primary mb-2">
                Close Position
              </button>
            </div>
            <div class="col-lg-2 col-md-4 col-sm-4 mb-1">
              <Field
                type="text"
                class="form-control"
                name="ledger_balance"
                placeholder="SEARCH"
                v-model="keyword" />
            </div>

            <div class="text-right col-lg-2 col-md-4 col-sm-4 mt-4">
              Total M2M :
              <span :style="totalM2M < 0 ? 'color: red' : 'color: blue'">{{
                totalM2M
                  ? $helperService.getThousandConverter(totalM2M.toFixed(2))
                  : 0
              }}</span>
            </div>
          </div>

          <div class="sticky-table-header">
            <table
              id="table"
              style="margin-top: 16px"
              data-toggle="table"
              data-search="true"
              data-filter-control="true"
              data-toolbar="#toolbar"
              class="table table-sm text-center text-white table-bordered product-list table-hover">
              <thead>
                <tr>
                  <th class="head ml-2">
                    <div class="switchToggleRead">
                      <input
                        type="checkbox"
                        v-model="isChecked"
                        :true-value="true"
                        :false-value="false"
                        v-on:change="checkall(isChecked)" />
                    </div>
                  </th>
                  <th
                    class="head sorting"
                    id="id-instrument_token"
                    v-on:click="
                      sorting('trades.exchange', 'id-instrument_token')
                    ">
                    Segment
                  </th>
                  <th
                    v-show="
                      data_type != 'SCRIPT_WISE' &&
                      data_type != 'SELF_SCRIPT_WISE'
                    "
                    class="head sorting"
                    id="id-name"
                    v-on:click="sorting('clientName', 'id-name', 'LOCAL')">
                    Client
                  </th>
                  <th
                    class="head sorting"
                    id="id-trading_symbol"
                    v-on:click="
                      sorting('trades.trading_symbol', 'id-trading_symbol')
                    ">
                    Symbol
                  </th>
                  <th
                    class="head sorting"
                    id="id-quantity"
                    v-on:click="sorting('total_buy_quantity', 'id-quantity')">
                    Total Buy Qty
                  </th>

                  <th
                    class="head sorting"
                    id="id-avg_buy_price"
                    v-on:click="sorting('avg_buy_price', 'id-avg_buy_price')">
                    Buy Avg. Price
                  </th>
                  <th
                    class="head sorting"
                    id="id-total_sell_quantity"
                    v-on:click="
                      sorting('total_sell_quantity', 'id-total_sell_quantity')
                    ">
                    Total Sell Qty
                  </th>

                  <th
                    class="head sorting"
                    id="id-avg_sell_price"
                    v-on:click="sorting('avg_sell_price', 'id-avg_sell_price')">
                    Sell Avg. Price
                  </th>
                  <th
                    class="head sorting"
                    id="id-net_lot"
                    v-on:click="sorting('net_lot', 'id-net_lot', 'LOCAL')">
                    Net Lot
                  </th>
                  <th
                    class="head sorting"
                    id="id-net_qty"
                    v-on:click="sorting('net_qty', 'id-net_qty', 'LOCAL')">
                    Net Qty
                  </th>
                  <th
                    class="head sorting"
                    id="id-bea"
                    v-on:click="sorting('bea', 'id-bea', 'LOCAL')">
                    B.E. Avg.
                  </th>
                  <th
                    class="head sorting"
                    id="id-ltp"
                    v-on:click="sorting('ltp', 'id-ltp', 'LOCAL')">
                    LTP
                  </th>
                  <th
                    class="head sorting"
                    id="id-m2m"
                    v-on:click="sorting('m2m', 'id-m2m', 'LOCAL')">
                    MTM
                  </th>
                </tr>
              </thead>
              <tbody v-show="!loader">
                <tr v-for="(data, index) in datalist" :key="index">
                  <td
                    style="font-size: 11px"
                    class="pl-1 pt-1 pr-1 p-0"
                    :class="
                      Number(data.total_buy_quantity) -
                        Number(data.total_sell_quantity) !=
                      0
                        ? Number(data.total_buy_quantity) >
                          Number(data.total_sell_quantity)
                          ? 'body-buy'
                          : 'body-sell'
                        : ''
                    ">
                    <!-- v-if="data.net_qty != 0" -->
                    <div class="switchToggleRead" v-if="data.net_qty != 0">
                      <input
                        :id="'index_' + data.id"
                        type="checkbox"
                        v-model="data.isChecked"
                        v-on:change="checkHandlling(data.isChecked)"
                        :true-value="true"
                        :false-value="false" />
                    </div>
                  </td>
                  <td
                    style="cursor: pointer; font-weight: 600"
                    v-on:click="setBuySellDetail(data)"
                    :class="
                      Number(data.total_buy_quantity) -
                        Number(data.total_sell_quantity) !=
                      0
                        ? Number(data.total_buy_quantity) >
                          Number(data.total_sell_quantity)
                          ? 'body-buy'
                          : 'body-sell'
                        : 'body'
                    ">
                    {{
                      data.exchange
                        ? data.exchange == "NFO"
                          ? "NSE"
                          : "MCX"
                        : ""
                    }}
                  </td>
                  <td
                    v-show="
                      data_type != 'SCRIPT_WISE' &&
                      data_type != 'SELF_SCRIPT_WISE'
                    "
                    style="cursor: pointer; font-weight: 600"
                    v-on:click="setBuySellDetail(data)"
                    :class="
                      Number(data.total_buy_quantity) -
                        Number(data.total_sell_quantity) !=
                      0
                        ? Number(data.total_buy_quantity) >
                          Number(data.total_sell_quantity)
                          ? 'body-buy'
                          : 'body-sell'
                        : 'body'
                    ">
                    {{ data.clientName }}
                  </td>
                  <td
                    style="cursor: pointer; font-weight: 600"
                    v-on:click="setBuySellDetail(data)"
                    :class="
                      Number(data.total_buy_quantity) -
                        Number(data.total_sell_quantity) !=
                      0
                        ? Number(data.total_buy_quantity) >
                          Number(data.total_sell_quantity)
                          ? 'body-buy'
                          : 'body-sell'
                        : 'body'
                    ">
                    {{ data.trading_symbol ? data.trading_symbol : "" }}
                  </td>
                  <td
                    style="cursor: pointer; font-weight: 600"
                    v-on:click="setBuySellDetail(data)"
                    :class="
                      Number(data.total_buy_quantity) -
                        Number(data.total_sell_quantity) !=
                      0
                        ? Number(data.total_buy_quantity) >
                          Number(data.total_sell_quantity)
                          ? 'body-buy'
                          : 'body-sell'
                        : 'body'
                    ">
                    {{
                      Number(data.total_buy_quantity)
                        ? $helperService.getFormattedPrice(
                            parseInt(Number(data.total_buy_quantity))
                          )
                        : 0
                    }}
                  </td>
                  <td
                    style="cursor: pointer; font-weight: 600"
                    v-on:click="setBuySellDetail(data)"
                    :class="
                      Number(data.total_buy_quantity) -
                        Number(data.total_sell_quantity) !=
                      0
                        ? Number(data.total_buy_quantity) >
                          Number(data.total_sell_quantity)
                          ? 'body-buy'
                          : 'body-sell'
                        : 'body'
                    ">
                    {{
                      Number(data.avg_buy_price)
                        ? $helperService.getFormattedPrice(
                            Number(data.avg_buy_price)
                          )
                        : 0
                    }}
                  </td>
                  <td
                    style="cursor: pointer; font-weight: 600"
                    v-on:click="setBuySellDetail(data)"
                    :class="
                      Number(data.total_buy_quantity) -
                        Number(data.total_sell_quantity) !=
                      0
                        ? Number(data.total_buy_quantity) >
                          Number(data.total_sell_quantity)
                          ? 'body-buy'
                          : 'body-sell'
                        : 'body'
                    ">
                    {{
                      Number(data.total_sell_quantity)
                        ? $helperService.getFormattedPrice(
                            parseInt(Number(data.total_sell_quantity))
                          )
                        : 0
                    }}
                  </td>
                  <td
                    style="cursor: pointer; font-weight: 600"
                    v-on:click="setBuySellDetail(data)"
                    :class="
                      Number(data.total_buy_quantity) -
                        Number(data.total_sell_quantity) !=
                      0
                        ? Number(data.total_buy_quantity) >
                          Number(data.total_sell_quantity)
                          ? 'body-buy'
                          : 'body-sell'
                        : 'body'
                    ">
                    {{
                      Number(data.avg_sell_price)
                        ? $helperService.getFormattedPrice(
                            Number(data.avg_sell_price)
                          )
                        : 0
                    }}
                  </td>
                  <td
                    style="cursor: pointer; font-weight: 600"
                    v-on:click="setBuySellDetail(data)"
                    :class="
                      Number(data.total_buy_quantity) -
                        Number(data.total_sell_quantity) !=
                      0
                        ? Number(data.total_buy_quantity) >
                          Number(data.total_sell_quantity)
                          ? 'body-buy'
                          : 'body-sell'
                        : 'body'
                    ">
                    {{
                      data.net_lot
                        ? data_type == "SELF_SCRIPT_WISE" && data.net_lot != 0
                          ? ((data.net_lot * this.sharing) / 100).toFixed(2)
                          : data.net_lot?.toFixed(2)
                        : 0
                    }}
                  </td>

                  <td
                    style="cursor: pointer; font-weight: 600"
                    v-on:click="setBuySellDetail(data)"
                    :class="
                      Number(data.total_buy_quantity) -
                        Number(data.total_sell_quantity) !=
                      0
                        ? Number(data.total_buy_quantity) >
                          Number(data.total_sell_quantity)
                          ? 'body-buy'
                          : 'body-sell'
                        : 'body'
                    ">
                    {{
                      data_type == "SELF_SCRIPT_WISE" && data.net_qty != 0
                        ? ((data.net_qty * this.sharing) / 100).toFixed(2)
                        : data.net_qty
                    }}
                  </td>
                  <td
                    v-on:click="setBuySellDetail(data)"
                    style="cursor: pointer; font-weight: 600"
                    :class="
                      Number(data.total_buy_quantity) -
                        Number(data.total_sell_quantity) !=
                      0
                        ? Number(data.total_buy_quantity) >
                          Number(data.total_sell_quantity)
                          ? 'body-buy'
                          : 'body-sell'
                        : 'body'
                    ">
                    {{ Number(data.bea) ? data.bea?.toFixed(2) : 0 }}
                  </td>

                  <td
                    v-on:click="setBuySellDetail(data)"
                    style="
                      padding: 0px !important;
                      font-size: 13px !important;
                      cursor: pointer;
                    "
                    v-show="data.ltp">
                    <div
                      style="padding: 0.3rem"
                      :style="'background-color:' + data.ltpc">
                      {{
                        $helperService.getThousandConverter(
                          data.ltp?.toFixed(2)
                        )
                      }}
                    </div>
                  </td>
                  <td
                    v-on:click="setBuySellDetail(data)"
                    v-show="!data.ltp"
                    style="cursor: pointer"
                    :class="
                      Number(data.total_buy_quantity) >
                      Number(data.total_sell_quantity)
                        ? 'body-buy'
                        : 'body-sell'
                    ">
                    <!-- {{ data.m2m?.toFixed(2) }} -->
                  </td>
                  <td
                    v-on:click="setBuySellDetail(data)"
                    style="
                      font-weight: bold;
                      font-size: 13px !important;
                      cursor: pointer;
                    "
                    :class="
                      Number(data.total_buy_quantity) -
                        Number(data.total_sell_quantity) !=
                      0
                        ? Number(data.m2m?.toFixed(2)) > 0
                          ? 'body-buy'
                          : 'body-sell'
                        : 'body'
                    ">
                    {{
                      $helperService.getThousandConverter(
                        data_type == "SELF_SCRIPT_WISE"
                          ? ((sharing * data.m2m) / 100).toFixed(2)
                          : data.m2m?.toFixed(2)
                      )
                    }}
                  </td>
                </tr>
              </tbody>
            </table>
            <ErrorComponent @retry="getList()" ref="errorComponent" />
          </div>
        </div>
      </div>
    </div>

    <!-- ===================================== -->
    <div
      id="dashfoot"
      v-if="is_show_buy_sell_section"
      class="buy-sell-section ng-scope"
      :style="
        buySellObj.order_action == 'BUY'
          ? 'background-color:rgb(31, 88, 204)'
          : 'background-color:rgb(161, 51, 41)'
      ">
      <Form ref="buySell" @submit="submit()">
        <div class="row" style="margin-left: 0px; margin-right: 0px">
          <div class="col-12 col-lg-3 col-xl-3">
            <div class="form-group row">
              <div class="col-sm-12">
                <br />
                <Field
                  type="text"
                  class="form-control"
                  name="buySellObjName"
                  placeholder="Script"
                  disabled
                  v-model="buySellObj.trading_symbol" />
              </div>
            </div>
          </div>

          <div
            v-if="buySellObj.order_action == 'SELL'"
            :style="'background-color:' + buySellObj.bc + '! important'"
            class="form-group col-sm-1 text-center trade-font-size-other trade-price red">
            <label class="col-form-label" style="color: white !important"
              >Bid Rate </label
            ><br />
            <span class="ng-binding" style="color: white !important">{{
              buySellObj.b?.toFixed(2)
            }}</span>
          </div>
          <div
            v-if="buySellObj.order_action == 'BUY'"
            :style="'background-color:' + buySellObj.ac + '! important'"
            class="form-group col-sm-1 text-center trade-font-size-other trade-price red">
            <label class="col-form-label" style="color: white !important"
              >Ask Rate </label
            ><br />
            <span class="ng-binding" style="color: white !important">{{
              buySellObj.a?.toFixed(2)
            }}</span>
          </div>
          <div
            :style="'background-color:' + buySellObj.ltpc + '! important'"
            class="form-group col-sm-1 text-center trade-font-size-other trade-price red">
            <label class="col-form-label" style="color: white !important"
              >LTP</label
            ><br />
            <span class="ng-binding" style="color: white !important">{{
              buySellObj.ltp?.toFixed(2)
            }}</span>
          </div>
          <div
            class="form-group col-sm-1 text-center trade-font-size-other trade-price">
            <label class="col-form-label">Change %</label><br /><span>{{
              buySellObj.ch?.toFixed(2)
            }}</span>
          </div>
          <div
            class="form-group col-sm-1 text-center trade-font-size-other trade-price">
            <label class="col-form-label">Net Chg</label><br />
            <span class="ng-binding">{{ buySellObj.n?.toFixed(2) }}</span>
          </div>
          <div
            class="form-group col-sm-1 text-center trade-font-size-other trade-price">
            <label class="col-form-label">High</label><br />
            <span class="ng-binding">{{ buySellObj.h?.toFixed(2) }}</span>
          </div>
          <div
            class="form-group col-sm-1 text-center trade-font-size-other trade-price">
            <label class="col-form-label">Low</label><br />
            <span class="ng-binding">{{ buySellObj.l?.toFixed(2) }}</span>
          </div>
          <div
            class="form-group col-sm-1 text-center trade-font-size-other trade-price">
            <label class="col-form-label">Open</label><br />
            <span class="ng-binding">{{ buySellObj.o?.toFixed(2) }}</span>
          </div>
          <div
            class="form-group col-sm-1 text-center trade-font-size-other trade-price">
            <label class="col-form-label">Close</label><br />
            <span class="ng-binding">{{ buySellObj.c?.toFixed(2) }}</span>
          </div>
        </div>
        <div class="col-12 row">
          <div class="col-sm-2 mt-2">
            <div class="custom-control custom-radio d-block float-left">
              <Field
                v-model="buySellObj.order_action"
                type="radio"
                value="BUY"
                id="gross_partnership"
                name="net_gross_partner"
                class="custom-control-input ng-pristine ng-untouched ng-valid"
                checked="checked"
                disabled
                style="height: unset !important" />
              <label for="gross_partnership" class="custom-control-label"
                >Buy</label
              >
            </div>
            <div class="custom-control custom-radio d-block float-right">
              <Field
                v-model="buySellObj.order_action"
                type="radio"
                value="SELL"
                id="net_partnership"
                disabled
                name="net_gross_partner"
                class="custom-control-input ng-pristine ng-untouched ng-valid"
                style="height: unset !important" />
              <label for="net_partnership" class="custom-control-label"
                >Sell</label
              >
            </div>
            <Field
              name="order_type"
              class="register-select mt-2"
              as="select"
              v-model="buySellObj.order_type"
              rules="required: order type"
              :validateOnInput="true">
              <option value="" disabled>Order Type</option>
              <option value="MARKET">Market</option>
              <option value="LIMIT">Limit</option>
              <!-- <option value="INTRADAY">Intraday</option>
              <option value="STOPLOSS">Stoploss</option> -->
            </Field>
            <ErrorMessage name="order_type" class="text-white" />
          </div>
          <div class="col-sm-2 mt-1" v-if="buySellObj.order_type != 'INTRADAY'">
            <label
              style="
                padding-top: 0px;
                padding-bottom: 0px;
                font-size: 14px !important;
              "
              class="col-form-label"
              >Lot</label
            ><br />
            <Field
              type="number"
              class="form-control"
              name="Lot"
              min="1"
              autocomplete="off"
              v-model="buySellObj.lot_size"
              v-on:keyup="updateQuantity(buySellObj.lot_size)"
              placeholder="Lot"
              rules="required:lot"
              :validateOnInput="true"
              v-on:keypress="$helperService.allowDecimalValue($event)" />
          </div>
          <div class="col-sm-2 mt-1" v-if="buySellObj.order_type != 'INTRADAY'">
            <label
              style="
                padding-top: 0px;
                padding-bottom: 0px;
                font-size: 14px !important;
              "
              class="col-form-label"
              >Qty</label
            ><br />

            <Field
              type="number"
              class="form-control"
              min="1"
              autocomplete="off"
              name="Qty"
              :style="buySellObj.exchange == 'MCX' ? 'cursor: no-drop;' : ''"
              v-model="buySellObj.quantity"
              v-on:keyup="updateLot(buySellObj.lot_size)"
              placeholder="Qty"
              rules="required:quantity"
              :validateOnInput="true"
              :disabled="buySellObj.exchange == 'MCX'"
              v-on:keypress="$helperService.allowDecimalValue($event)" />
            <ErrorMessage name="Qty" class="text-white" />
            <p v-if="isQuantity" style="font-size: 11px; color: white">
              {{ message }}
            </p>
          </div>
          <div
            class="col-sm-2 mt-1"
            v-if="
              buySellObj.order_type == 'MARKET' &&
              buySellObj.order_action == 'BUY'
            ">
            <label
              style="
                padding-top: 0px;
                padding-bottom: 0px;
                font-size: 14px !important;
              "
              class="col-form-label"
              >Price</label
            ><br />
            <Field
              type="text"
              class="form-control"
              name="Price"
              placeholder="Price"
              autocomplete="off"
              :disabled="buySellObj.order_type == 'MARKET'"
              v-model="buySellObj.a"
              rules="required:price"
              :validateOnInput="true"
              v-on:keypress="$helperService.allowDecimalValue($event)" />
            <ErrorMessage name="Price" class="text-white" />
          </div>
          <div
            class="col-sm-2 mt-1"
            v-if="
              buySellObj.order_type == 'MARKET' &&
              buySellObj.order_action == 'SELL'
            ">
            <label
              style="
                padding-top: 0px;
                padding-bottom: 0px;
                font-size: 14px !important;
              "
              class="col-form-label"
              >Price</label
            ><br />
            <Field
              type="text"
              class="form-control"
              name="marketPrice"
              autocomplete="off"
              placeholder="Price"
              :disabled="buySellObj.order_type == 'MARKET'"
              v-model="buySellObj.b"
              rules="required:price"
              :validateOnInput="true"
              v-on:keypress="$helperService.allowDecimalValue($event)" />
            <ErrorMessage name="Price" class="text-white" />
          </div>
          <div class="col-sm-2 mt-1" v-if="buySellObj.order_type == 'LIMIT'">
            <label
              style="
                padding-top: 0px;
                padding-bottom: 0px;
                font-size: 14px !important;
              "
              class="col-form-label"
              >Price</label
            ><br />
            <Field
              type="text"
              autocomplete="off"
              class="form-control"
              name="limitPrice"
              placeholder="Price"
              v-model="buySellObj.price"
              rules="required:price"
              :validateOnInput="true"
              v-on:keyup="checkMultiple(buySellObj.price, buySellObj.tick_size)"
              v-on:keypress="$helperService.allowDecimalValue($event)" />
            <ErrorMessage name="Price" class="text-white" />
            <p v-if="isTickSize" style="font-size: 13px; color: white">
              Price Should be in multiple of {{ buySellObj.tick_size }}
            </p>
          </div>
          <div class="col-sm-2 mt-1" v-if="buySellObj.order_type == 'STOPLOSS'">
            <label
              style="
                padding-top: 0px;
                padding-bottom: 0px;
                font-size: 14px !important;
              "
              class="col-form-label"
              >Price</label
            ><br />
            <Field
              type="text"
              class="form-control"
              autocomplete="off"
              name="stoplossPrice"
              placeholder="Price"
              v-model="buySellObj.price"
              rules="required:price"
              :validateOnInput="true"
              v-on:keyup="checkMultiple(buySellObj.price, buySellObj.tick_size)"
              v-on:keypress="$helperService.allowDecimalValue($event)" />
            <ErrorMessage name="Price" class="text-white" />
            <p v-if="isTickSize" style="font-size: 13px; color: white">
              Price Should be in multiple of {{ buySellObj.tick_size }}
            </p>
          </div>
          <div class="col-sm-2 mt-1">
            <label
              style="
                padding-top: 0px;
                padding-bottom: 0px;
                font-size: 14px !important;
              "
              class="col-form-label"
              >Client</label
            ><br />
            <Field
              type="text"
              name="user"
              disabled
              v-model="buySellObj.user_id"
              rules="required:user"
              :validateOnInput="true">
              <Multiselect
                mode="single"
                trackBy="new_name"
                label="new_name"
                valueProp="id"
                class="register-select"
                placeholder="Client"
                disabled
                v-model="buySellObj.user_id"
                :options="client_list"
                openDirection="top"
                searchable="true" />
              <ErrorMessage name="user" class="text-white" />
            </Field>
          </div>
          <div class="col-sm-2 mt-4">
            <button id="submitForm" class="btn btn-success m-1">
              <i class="fa fa-trade"></i>
              <span> Submit </span>
            </button>
            <button
              type="button"
              v-on:click="closeBuySellPopup()"
              class="btn btn-danger m-1">
              <i class="fa fa-trade"></i>
              <span> Cancel </span>
            </button>
          </div>
        </div>
      </Form>
    </div>
  </div>
</template>
<script>
import ErrorComponent from "@/components/ErrorComponent";
import moment from "moment";
import { Form, Field, ErrorMessage } from "vee-validate";
import Multiselect from "@vueform/multiselect";
import downloadexcel from "vue-json-excel3";
import Datepicker from "vue3-datepicker";
import webstomp from "webstomp-client";
import swal from "sweetalert2";
import PageTitle from '../../components/PageTitle'

export default {
  created() {
    let that = this;
    document.addEventListener("keyup", function (evt) {
      if (evt.keyCode === 27) {
        that.closeBuySellPopup();
      }
    });
  },
  components: {
    Field,
    Form,
    ErrorMessage,
    ErrorComponent,
    Multiselect,
    downloadexcel,
    Datepicker,
    PageTitle
  },
  name: "ProfileV2",
  computed: {
    brokerFilterList() {
      if (this.master_id)
        return this.brokerList.filter((e) => {
          return e.master_id == this.master_id;
        });
      else return this.brokerList;
    },
    sharing() {
      return this.$store.getters.getUser?.sharing;
    },
    totalM2M() {
      var m2m = 0;
      this.datalist.map((e) => {
        m2m += e.m2m
          ? this.data_type == "SELF_SCRIPT_WISE"
            ? (this.sharing * e.m2m) / 100
            : e.m2m
          : 0;
      });

      return m2m;
    },
    datalist() {
      return this.keyword
        ? this.list.filter((e) => {
            return (
              e.user?.name?.toLowerCase().indexOf(this.keyword.toLowerCase()) >
                -1 ||
              e.user?.code?.toLowerCase().indexOf(this.keyword.toLowerCase()) >
                -1 ||
              e.name?.toLowerCase().indexOf(this.keyword.toLowerCase()) > -1
            );
          })
        : this.list;
    },
  },
  data() {
    return {
      loader: true,
      role: this.$store.getters.getUser?.role,
      buySellObj: {
        order_action: "BUY",
        order_type: "MARKET",
      },
      is_show_buy_sell_section: false,
      isChecked: false,
      isQuantity: false,
      isTickSize: false,
      lot_size: 0,
      net_qty: "ALL",
      data_type: "",
      message: "",
      exchange: "",
      user_id: "",
      authority_id: "",
      manager_id: "",
      manager_list: [],
      broker_id: "",
      master_id: "",
      list: [],
      expireDate: "",
      type: "",
      keyword: "",
      client_list: [],
      master_list: [],
      authority_list: [],
      script_list: [],
      json_fields: {
        Segment: "exchange",
        "Client Code": "client",
        "Script Name": "trading_symbol",
        "Expire Date": "expiry",
        "Total Buy QTY": "total_buy_quantity",
        "Buy Avg Price": "avg_buy_price",
        "Total Sell QTY": "total_sell_quantity",
        "Sell Avg Price": "avg_sell_price",
        "Net Qty": "net_qty",
        "B.E Avg": "be_avg",
        LTP: "ltp",
        MTM: "mtm_profit",
      },
      excel_data: [],
      brokerList: [],
      exchanges: [
        {
          name: "NSE",
          value: "NFO",
        },
        {
          name: "MCX",
          value: "MCX",
        },
      ],
      tradeType: [
        {
          name: "BUY",
          value: "BUY",
        },
        {
          name: "SELL",
          value: "SELL",
        },
      ],
    };
  },
  mounted() {
    this.getList();
    this.getClientList();
    this.getMasterList();
    this.getBrokerList(0);
    this.getScriptList(null, "FIRST");
    this.getManagerList();
    this.connect();
    var $this = this;
    setInterval(() => {
      $this.checkConnection();
    }, 5000);
  },
  methods: {
    getManagerList() {
      this.$api
        .getAPI({
          _action: "/manager-list",
        })
        .then((res) => {
          this.manager_list = res;
          for (var i in this.manager_list) {
            this.manager_list[i].new_name =
              this.manager_list[i].name +
              " (" +
              this.manager_list[i].code +
              ")";
          }
        })
        .catch(() => {});
    },
    getBrokerList() {
      this.$api
        .getAPI({
          _action: "/broker-list",
        })
        .then((res) => {
          this.brokerList = res;
          for (var i in this.brokerList) {
            this.brokerList[i].new_name =
              this.brokerList[i].name + " (" + this.brokerList[i].code + ")";
          }
        })
        .catch(() => {});
    },
    getMasterList() {
      this.$api
        .getAPI({
          _action: "/master-list",
        })
        .then((res) => {
          this.master_list = res;
          for (var i in this.master_list) {
            this.master_list[i].new_name =
              this.master_list[i].name + " (" + this.master_list[i].code + ")";
          }
        })
        .catch(() => {});
    },

    checkMultiple(price, tick_size) {
      if (price && tick_size) {
        var n = price;
        n = (n - Math.floor(n)).toFixed(2);
        var tick = tick_size;
        var is_check = (n / tick).toFixed(2) % 1 == 0;
        if (is_check) {
          this.isTickSize = false;
        } else {
          this.isTickSize = true;
        }
        console.log("is_check", this.isTickSize);
      }
    },
    getList(orderBy, sortBy) {
      this.$refs.errorComponent.updateFormLoader(true);
      this.list = [];
      var searchObj = {};
      if (this.expireDate) {
        searchObj.expireDate = moment(this.expireDate).format("yyyy-MM-DD");
      }
      if (orderBy) {
        searchObj.order_by = orderBy;
      }
      if (this.keyword) {
        searchObj.keyword = this.keyword;
      }
      if (this.authority_id) {
        searchObj.authority_id = this.authority_id;
      }
      if (this.master_id) {
        searchObj.master_id = this.master_id;
      }
      if (this.broker_id) {
        searchObj.broker_id = this.broker_id;
      }
      if (this.manager_id) {
        searchObj.manager_id = this.manager_id;
      }
      if (this.data_type) {
        searchObj.data_type = this.data_type;
      }
      if (this.exchange) {
        searchObj.exchange = this.exchange;
      }
      if (this.user_id) {
        searchObj.user_id = this.user_id;
      }
      if (this.net_qty) {
        searchObj.net_qty = this.net_qty;
      }
      if (sortBy) {
        searchObj.sort_by = sortBy;
      }
      this.$api
        .getAPI({
          _action: "/position-report-list-v2",
          _body: searchObj,
        })
        .then((res) => {
          var arr = [];
          if (this.net_qty == "OUTSTANDING") {
            for (var l in res) {
              if (Number(res[l].net_qty) != 0) {
                arr.push(res[l]);
              }
            }
            this.list = arr;
          } else {
            this.list = res;
          }

          for (var k in this.list) {
            this.list[k].clientName = this.$helperService.getUpperCase(
              this.list[k].user?.name + "-" + this.list[k].user?.code
            );

            this.list[k].isChecked = false;
            if (this.list[k].net_qty != 0) {
              this.list[k].bea = Math.abs(
                (this.list[k].total_sell_quantity *
                  this.list[k].avg_sell_price -
                  this.list[k].total_buy_quantity *
                    this.list[k].avg_buy_price) /
                  this.list[k].net_qty
              );
            }
          }

          this.loader = false;
          if (this.$refs.errorComponent) {
            this.$refs.errorComponent.setData(
              { list: this.list },
              "No Data available"
            );
          }

          if (
            this.data_type == "SCRIPT_WISE" ||
            this.data_type == "SELF_SCRIPT_WISE"
          ) {
            this.getScriptWise();
          }

          this.$refs.errorComponent.updateFormLoader(false);
        })
        .catch(() => {
          this.loader = false;
          if (this.$refs.errorComponent) {
            this.$refs.errorComponent.setData(0, undefined, "");
            this.$refs.errorComponent.updateFormLoader(false);
          }
        });
    },
    checkConnection() {
      if (!this.client || !this.client.connected) {
        // console.log("try to reconnect connected");
        this.connect();
      } else {
        // console.log("already connected");
      }
    },
    connect() {
      var $this = this;
      this.client = webstomp.client(this.$api.url, { debug: false });
      this.client.connect(this.$api.username, this.$api.password, () => {
        this.client.subscribe("/exchange/market-data", (message) => {
          $this.showData(JSON.parse(message.body));
        });
      });
    },
    showData(data) {
      for (var i in data) {
        for (var j in this.list) {
          if (this.list[j].instrument_token == data[i].it) {
            this.list[j].ltp = data[i].ltp;
            this.list[j].ltpc = data[i].ltpc;
            if (this.list[j].net_qty != 0) {
              this.list[j].m2m =
                this.$helperService.getFormattedPrice(
                  this.list[j].ltp - this.list[j].bea
                ) * this.list[j].net_qty;
            } else {
              this.list[j].m2m =
                this.list[j].total_sell_quantity * this.list[j].avg_sell_price -
                this.list[j].total_buy_quantity * this.list[j].avg_buy_price;
            }
          }
        }
        if (data[i].it == this.buySellObj.instrument_token) {
          console.log(this.buySellObj);
          this.buySellObj.a = data[i].a;
          this.buySellObj.ba = data[i].ba;
          this.buySellObj.ac = data[i].ac;
          this.buySellObj.b = data[i].b;
          this.buySellObj.bc = data[i].bc;
          this.buySellObj.a = data[i].a;
          this.buySellObj.ch = data[i].ch;
          this.buySellObj.h = data[i].h;
          this.buySellObj.l = data[i].l;
          this.buySellObj.o = data[i].o;
          this.buySellObj.c = data[i].c;
          this.buySellObj.ltp = data[i].ltp;
          this.buySellObj.ltpc = data[i].ltpc;
          this.buySellObj.n = data[i].n;
          this.buySellObj.ll = data[i].ll;
          this.buySellObj.ul = data[i].ul;
          this.buySellObj.lr = data[i].lr;
        }
      }
      this.excel_data = [];
      if (this.list.length > 0) {
        for (var k in this.list) {
          var obj = {};
          obj.exchange = this.list[k].script
            ? this.list[k].script.exchange == "NFO"
              ? "NSE"
              : "MCX"
            : "";
          obj.client = this.list[k].user
            ? this.$helperService.getUpperCase(
                this.list[k].user?.code + "-" + this.list[k].user?.server_code
              )
            : "";
          obj.expiry = this.list[k].expiry;
          obj.time = this.$helperService.getTime(this.list[k].created_at);
          obj.client = this.list[k].user
            ? this.$helperService.getUpperCase(
                this.list[k].user.name + "-" + this.list[k].user.code
              )
            : "";
          obj.trading_symbol = this.list[k].trading_symbol;
          obj.total_buy_quantity = this.$helperService.getFormattedPrice(
            this.list[k].total_buy_quantity
          );
          obj.avg_buy_price = this.$helperService.getFormattedPrice(
            this.list[k].avg_buy_price
          );
          obj.total_sell_quantity = this.$helperService.getFormattedPrice(
            this.list[k].total_sell_quantity
          );
          obj.avg_sell_price = this.$helperService.getFormattedPrice(
            this.list[k].avg_sell_price
          );
          obj.net_qty = this.list[k].net_qty;
          obj.be_avg = this.list[k].bea;
          obj.ltp = this.list[k].ltp;
          obj.mtm_profit = this.list[k].m2m;
          this.excel_data.push(obj);
        }
      }

      this.$refs.errorComponent?.updateFormLoader(false);
    },
    updateQuantity(lot) {
      this.buySellObj.quantity = lot * this.lot_size;

      var quantity = this.buySellObj.quantity;
      var max_quantity = Math.abs(this.buySellObj.net_qty);
      // alert(quantity + " " + max_quantity);
      if (quantity > max_quantity) {
        this.isQuantity = true;
        this.message =
          "Quantity Should be less then or equal to " + max_quantity;
      } else {
        this.isQuantity = false;
        this.message = "";
      }
    },
    updateLot() {
      this.buySellObj.lot_size = this.$helperService.getFormattedPrice(
        this.buySellObj.quantity / this.lot_size
      );
      var quantity = this.buySellObj.quantity;
      var max_quantity = Math.abs(this.buySellObj.net_qty);
      if (quantity > max_quantity) {
        this.isQuantity = true;
        this.message =
          "Quantity Should be less then or equal to " + max_quantity;
      } else {
        this.isQuantity = false;
        this.message = "";
      }
    },
    submit() {
      if (this.isTickSize) {
        return;
      }
      if (this.isQuantity) {
        return;
      }

      swal
        .fire({
          title: "Are you sure?",
          text: "You sure want to place order.",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "Place Order",
          customClass: {
            confirmButton: "btn btn-blue btn-fill",
            cancelButton: "btn btn-danger btn-fill",
          },
        })
        .then(async (result) => {
          if (result.isConfirmed) {
            if (
              Number(this.buySellObj.price) < Number(this.buySellObj.ll) &&
              this.buySellObj.order_type != "MARKET" &&
              this.buySellObj.order_type != "INTRADAY"
            ) {
              swal.fire({
                title: "Error",
                text:
                  "price should be greater then lower limit " +
                  this.buySellObj.ll +
                  ".",
                icon: "error",
                showCancelButton: true,
                showConfirmButton: false,
              });
              this.reject_reason =
                "price should be greater then lower limit " +
                this.buySellObj.ll +
                ".";
            }
            if (Number(this.buySellObj.price) > Number(this.buySellObj.ul)) {
              swal.fire({
                title: "Error",
                text:
                  "price should be less then upper limit " +
                  this.buySellObj.ul +
                  ".",
                icon: "error",
                showCancelButton: true,
                showConfirmButton: false,
              });
              this.reject_reason =
                "price should be less then upper limit " +
                this.buySellObj.ul +
                ".";
            }

            if (
              this.buySellObj.order_action == "BUY" &&
              this.buySellObj.order_type == "STOPLOSS"
            ) {
              if (
                this.buySellObj.price <= this.buySellObj.ltp &&
                this.buySellObj.price <= this.buySellObj.a
              ) {
                swal.fire({
                  title: "Error",
                  text: "Stoploss price should be greater then ltp price.",
                  icon: "error",
                  showCancelButton: true,
                  showConfirmButton: false,
                });
                this.reject_reason =
                  "Stoploss price should be greater then ltp price.";
              }
            }

            if (
              this.buySellObj.order_action == "SELL" &&
              this.buySellObj.order_type == "STOPLOSS"
            ) {
              if (
                this.buySellObj.price >= this.buySellObj.ltp &&
                this.buySellObj.price >= this.buySellObj.b
              ) {
                swal.fire({
                  title: "Error",
                  text: "Stoploss price should be less then ltp price.",
                  icon: "error",
                  showCancelButton: true,
                  showConfirmButton: false,
                });
                this.reject_reason =
                  "Stoploss price should be less then ltp price.";
              }
            }

            if (this.buySellObj.order_type == "LIMIT") {
              if (
                this.buySellObj.price >= this.buySellObj.b &&
                this.buySellObj.price <= this.buySellObj.a
              ) {
                swal.fire({
                  title: "Error",
                  text: "Limit price should not be in between bid price and ask price.",
                  icon: "error",
                  showCancelButton: true,
                  showConfirmButton: false,
                });
                this.reject_reason =
                  "Limit price should not be in between bid price and ask price.";
              }
            }
            if (
              this.buySellObj.order_type == "LIMIT" &&
              this.buySellObj.order_action == "BUY"
            ) {
              if (this.buySellObj.price > this.buySellObj.ltp) {
                swal.fire({
                  title: "Error",
                  text: "Limit price should be Less then LTP.",
                  icon: "error",
                  showCancelButton: true,
                  showConfirmButton: false,
                });
                this.reject_reason = "Limit price should be Less then LTP.";
              }
            }
            if (
              this.buySellObj.order_type == "LIMIT" &&
              this.buySellObj.order_action == "SELL"
            ) {
              if (this.buySellObj.price < this.buySellObj.ltp) {
                swal.fire({
                  title: "Error",
                  text: "Limit price should be greater then LTP.",
                  icon: "error",
                  showCancelButton: true,
                  showConfirmButton: false,
                });
                this.reject_reason = "Limit price should be greater then LTP.";
              }
            }
            this.buySellObj.device = "WEB";
            if (
              this.buySellObj.order_action == "BUY" &&
              this.buySellObj.order_type == "MARKET"
            ) {
              this.buySellObj.price = this.buySellObj.a;
            } else if (
              this.buySellObj.order_action == "SELL" &&
              this.buySellObj.order_type == "MARKET"
            ) {
              this.buySellObj.price = this.buySellObj.b;
            }

            if (this.reject_reason) {
              this.buySellObj.reject_reason = this.reject_reason;
            }
            if (this.buySellObj.price) {
              this.buySellObj.price = Number(this.buySellObj.price);
            }
            this.buySellObj.is_position = true;
            this.$api
              .postAPI({
                _action: "/add-trade",
                _body: this.buySellObj,
                _buttonId: "submitForm",
              })
              .then(() => {
                // this.is_show_buy_sell_section = false;

                if (!this.reject_reason) {
                  if (this.buySellObj.order_type == "MARKET") {
                    for (var i in this.client_list) {
                      if (this.client_list[i].id == this.buySellObj.user_id) {
                        var code = this.client_list[i].code;
                        var name = this.client_list[i].name;
                      }
                    }
                    var text =
                      "Trade has been successfully executed. " +
                      this.buySellObj.name_new +
                      "   " +
                      this.$helperService.getCustomDateYear(
                        this.buySellObj.expiry
                      ) +
                      "   " +
                      this.buySellObj.order_action +
                      " " +
                      this.buySellObj.quantity +
                      "@" +
                      Number(this.buySellObj.price).toFixed(2) +
                      "    For " +
                      this.$helperService.getUpperCase(name) +
                      "(" +
                      code +
                      ")";
                    if (this.buySellObj.order_action == "SELL") {
                      this.$notify({
                        title: "SELL",
                        type: "error",
                        text: text,
                      });
                    }
                    if (this.buySellObj.order_action == "BUY") {
                      this.$notify({
                        title: "BUY",
                        type: "success",
                        text: text,
                      });
                    }
                  } else {
                    for (var j in this.client_list) {
                      if (this.client_list[j].id == this.buySellObj.user_id) {
                        var code_new = this.client_list[j].code;
                        var name_new = this.client_list[j].name;

                        this.client_list[j].name +
                          this.$helperService.getCustomDate(
                            this.client_list[j].expiry
                          );
                      }
                    }
                    if (this.buySellObj.order_type != "INTRADAY") {
                      var text_new =
                        "Order Placed Successfully " +
                        this.buySellObj.name_new +
                        "   " +
                        this.$helperService.getCustomDateYear(
                          this.buySellObj.expiry
                        ) +
                        "   " +
                        this.buySellObj.order_action +
                        " " +
                        this.buySellObj.quantity +
                        "@" +
                        Number(this.buySellObj.price).toFixed(2) +
                        "    For " +
                        this.$helperService.getUpperCase(name_new) +
                        "(" +
                        code_new +
                        ")";
                      if (this.buySellObj.order_action == "SELL") {
                        this.$notify({
                          title: "SELL",
                          type: "error",
                          text: text_new,
                        });
                      }
                      if (this.buySellObj.order_action == "BUY") {
                        this.$notify({
                          title: "BUY",
                          type: "success",
                          text: text_new,
                        });
                      }
                    } else {
                      var text_new_2 =
                        "Intraday Close Order Placed Successfully " +
                        this.buySellObj.name_new +
                        "   " +
                        this.$helperService.getCustomDateYear(
                          this.buySellObj.expiry
                        ) +
                        "    For " +
                        this.$helperService.getUpperCase(name_new) +
                        "(" +
                        code_new +
                        ")";
                      this.$notify({
                        title: "",
                        type: "success",
                        text: text_new_2,
                      });
                    }
                  }
                }
                this.$refs.buySell.resetForm();
                this.buySellObj.price = "";
                this.buySellObj.quantity = "";
                this.buySellObj.lot = "";
                this.buySellObj.user_id = "";
                this.reject_reason = "";
                this.buySellObj.reject_reason = "";
                this.buySellObj.order_action = "BUY";
                this.buySellObj.order_type = "MARKET";
                this.is_show_buy_sell_section = false;
                this.getList();
              })
              .catch(() => {
                this.$refs.buySell.resetForm();
                this.buySellObj.price = "";
                this.buySellObj.quantity = "";
                this.buySellObj.lot = "";
                this.buySellObj.user_id = "";
                this.reject_reason = "";
                this.buySellObj.reject_reason = "";
                this.buySellObj.order_action = "BUY";
                this.buySellObj.order_type = "MARKET";
                this.is_show_buy_sell_section = false;
              });
          } else {
            this.$refs.buySell.resetForm();
            this.buySellObj.price = "";
            this.buySellObj.quantity = "";
            this.buySellObj.lot = "";
            this.buySellObj.user_id = "";
            this.reject_reason = "";
            this.buySellObj.reject_reason = "";
            this.buySellObj.order_action = "BUY";
            this.buySellObj.order_type = "MARKET";
            this.is_show_buy_sell_section = false;
          }
        });
    },
    setBuySellDetail(data) {
      if (data.net_qty == 0) {
        return;
      }

      if (!data.script || !data.script.lot_size) {
        return;
      }

      this.message = "";
      this.isQuantity = false;
      this.is_show_buy_sell_section = true;
      this.buySellObj = { ...data };
      this.buySellObj.order_type = "MARKET";
      this.buySellObj.lot_size = data.net_lot;
      this.buySellObj.user_id = data.user_id;
      this.lot_size = data.script.lot_size;

      this.buySellObj.lot_size = Math.abs(data.net_lot);
      this.buySellObj.quantity = Math.abs(this.buySellObj.net_qty);
      if (this.buySellObj.net_qty > 0) {
        this.buySellObj.order_action = "SELL";
      }
      if (this.buySellObj.net_qty < 0) {
        this.buySellObj.order_action = "BUY";
      }
      this.buySellObj.name_new =
        data.name + this.$helperService.getCustomDate(data.expiry);
      this.buySellObj.trading_symbol =
        data.name + " " + this.$helperService.getCustomDate(data.expiry);
    },
    closeBuySellPopup() {
      this.$refs.buySell.resetForm();
      this.buySellObj.price = "";
      this.buySellObj.quantity = "";
      this.buySellObj.lot = "";
      this.buySellObj.user_id = "";
      this.reject_reason = "";
      this.buySellObj.reject_reason = "";
      this.buySellObj.order_action = "BUY";
      this.buySellObj.order_type = "MARKET";
      this.is_show_buy_sell_section = false;
    },
    closePosition() {
      this.$refs.errorComponent.updateFormLoader(true);
      var obj = {};
      obj.close_position_array = [];
      obj.device = "WEB";
      for (var k in this.list) {
        if (this.list[k].isChecked == true) {
          obj.close_position_array.push(this.list[k]);
        }
      }

      if (obj.close_position_array.length == 0) {
        this.$notify({
          type: "error",
          text: "Please select Postions First",
        });
        this.$refs.errorComponent.updateFormLoader(false);
        return;
      }
      swal
        .fire({
          title: "Are you sure?",
          text: "Your all pending order will be cancelled if you close position for the selected scripts",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "Yes",
          customClass: {
            confirmButton: "btn btn-blue btn-fill",
            cancelButton: "btn btn-danger btn-fill",
          },
        })
        .then(async (result) => {
          if (result.isConfirmed) {
            this.$api
              .postAPI({
                _action: "/close-position",
                _body: obj,
              })
              .then((res) => {
                this.$refs.errorComponent.updateFormLoader(false);
                this.$notify({
                  title: "Success",
                  type: "success",
                  text: res.message,
                });
                this.getList();
              })
              .catch(() => {
                this.$refs.errorComponent.updateFormLoader(false);
              });
          }
        });
    },
    rollOverPosition() {
      this.$refs.errorComponent.updateFormLoader(true);
      var obj = {};
      var arr = [];
      obj.device = "WEB";
      for (var k in this.list) {
        if (this.list[k].isChecked == true) {
          arr.push(this.list[k]);
        }
      }
      obj.close_position_array = arr;
      if (arr.length == 0) {
        this.$notify({
          type: "error",
          text: "Please select Postions First",
        });
        this.$refs.errorComponent.updateFormLoader(false);
        return;
      }
      swal
        .fire({
          title: "Are you sure?",
          text: "Your all pending order will be cancelled if you roll over your position for the selected scripts",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "Yes",
          customClass: {
            confirmButton: "btn btn-blue btn-fill",
            cancelButton: "btn btn-danger btn-fill",
          },
        })
        .then(async (result) => {
          if (result.isConfirmed) {
            this.$api
              .postAPI({
                _action: "/roll-over",
                _body: obj,
              })
              .then((res) => {
                this.$refs.errorComponent.updateFormLoader(false);
                this.$notify({
                  title: "Success",
                  type: "success",
                  text: res.message,
                });
                this.getList();
              })
              .catch(() => {
                this.$refs.errorComponent.updateFormLoader(false);
              });
          }
        });
    },
    getScriptList(exchange, type) {
      var searchObj = {};
      if (this.exchange) {
        searchObj.exchange = exchange;
      }
      this.$api
        .getAPI({
          _action: "/script-list-by-exchange",
          _body: searchObj,
        })
        .then((res) => {
          this.script_list = res;
          if (!type) {
            this.filterData();
          }
        })
        .catch(() => {});
    },
    getClientList() {
      this.$api
        .getAPI({
          _action: "/client-list",
        })
        .then((res) => {
          this.client_list = res;
          for (var i in this.client_list) {
            this.client_list[i].new_name =
              this.client_list[i].name + " (" + this.client_list[i].code + ")";
          }
        })
        .catch(() => {});
    },
    sorting(sortBy, id, type) {
      this.$refs.errorComponent.updateFormLoader(true);
      this.loader = true;
      if (!type) {
        var obj = this.$helperService.sorting(sortBy, id);
        this.getList(obj.order_by, obj.sort_by);
      } else {
        var $this = this;
        setTimeout(() => {
          $this.list = $this.$helperService.localSorting(
            $this.list,
            sortBy,
            id
          );
          $this.$refs.errorComponent.updateFormLoader(false);
          $this.loader = false;
        }, 1000);
      }
    },
    async fetchData() {
      return this.excel_data;
    },
    checkall(isChecked) {
      for (var k in this.list) {
        if (isChecked) {
          if (this.list[k].net_qty != 0) {
            this.list[k].isChecked = true;
          }
        } else if (!isChecked) {
          this.list[k].isChecked = false;
        }
      }
    },
    checkHandlling(isChecked) {
      if (!isChecked) {
        this.isChecked = false;
      } else if (isChecked) {
        var count = 0;
        for (var k in this.list) {
          if (this.list[k].isChecked == false) {
            count = count + 1;
          }
        }
      }

      if (count == 0) {
        this.isChecked = true;
      }
    },
    startDownload() {
      console.log("d");
    },
    finishDownload() {
      console.log("d");
    },
    getScriptWise() {
      if (this.list) {
        var temp = [];
        for (var script of this.list) {
          var userIndex = -1;

          for (var j in temp) {
            if (temp[j].name == script.name) {
              userIndex = j;
            }
          }

          if (userIndex == -1) {
            temp.push({
              ...script,
              total_buy_quantity: Number(script.total_buy_quantity),
              total_sell_quantity: Number(script.total_sell_quantity),
              net_qty: Number(script.net_qty),
              net_lot: Number(script.net_lot),
              avg_buy_price:
                Number(script.avg_buy_price) *
                Number(script.total_buy_quantity),
              avg_sell_price:
                Number(script.avg_sell_price) *
                Number(script.total_sell_quantity),
            });
          } else {
            temp[userIndex].total_buy_quantity =
              temp[userIndex].total_buy_quantity +
              Number(script.total_buy_quantity);
            temp[userIndex].total_sell_quantity =
              temp[userIndex].total_sell_quantity +
              Number(script.total_sell_quantity);
            temp[userIndex].net_qty =
              temp[userIndex].net_qty + Number(script.net_qty);
            temp[userIndex].net_lot =
              temp[userIndex].net_lot + Number(script.net_lot);
            temp[userIndex].avg_sell_price =
              temp[userIndex].avg_sell_price +
              Number(script.avg_sell_price) *
                Number(script.total_sell_quantity);
            temp[userIndex].avg_buy_price =
              temp[userIndex].avg_buy_price +
              Number(script.avg_buy_price) * Number(script.total_buy_quantity);
          }
        }
        for (var obj of temp) {
          obj.avg_sell_price =
            obj.total_sell_quantity != 0
              ? obj.avg_sell_price / obj.total_sell_quantity
              : 0;
          obj.avg_buy_price =
            obj.total_buy_quantity != 0
              ? obj.avg_buy_price / obj.total_buy_quantity
              : 0;
          obj.bea =
            obj.net_qty != 0
              ? (obj.total_buy_quantity * obj.avg_buy_price -
                  obj.total_sell_quantity * obj.avg_sell_price) /
                obj.net_qty
              : 0;
          //    obj.bea =
          // obj.net_qty != 0
          //   ? Math.abs(((obj.total_sell_quantity * obj.avg_sell_price) - (obj.total_buy_quantity * obj.avg_buy_price)
          //     ) / Math.abs(obj.net_qty))
          //   : 0;
          obj.m2m =
            this.$helperService.getFormattedPrice(obj.ltp - obj.bea) *
            obj.net_qty;
        }

        this.list = [...temp];
      }
    },
    reset() {
      this.data_type = "";
      this.fromDate = "";
      this.toDate = "";
      this.getList();
    },
  },
};
</script>
<style src="@vueform/multiselect/themes/default.css"></style>
<style scoped>
.multiselect {
  background: white !important;
  max-height: 30px !important;
  min-height: 0px !important;
}

.multiselect-tag {
  background: #9cc338 !important;
}

.option-icon {
  width: 25px;
  height: 25px;
  border-radius: 15px;
  margin-right: 10px;
}
</style>
